import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import styles from "./Invoices.module.scss";
import moment from "moment";
// import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Unstable_Grid2";
import { ReactComponent as DownloadIcon } from "./csv.svg";
import { Divider } from "@mui/material";
import InvoiceSection from "./InvoiceSection";
import DownloadPDFButton from "./Download";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const items = [
  {
    description: "A new Devop ebook",
    quantity: 1,
    unitPrice: 10000,
    amount: 45000
  },
  {
    description: "A new Devop ebook",
    quantity: 1,
    unitPrice: 10000,
    amount: 45000
  },
  {
    description: "A new Devop ebook",
    quantity: 1,
    unitPrice: 10000,
    amount: 45000
  }
];

function InvoiceRefComponent() {
  const params = useParams();
  const [paymentData, setPaymentData] = useState();
  const [subsidiaryDetails, setSubsidiaryDetails] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState();

  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT
    });
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const { status, data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/subsidiary/dashboard/invoice/public/detail/${params?.invoice}`
        );
        status == 200 && setPaymentData(data?.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error.response) {
          if (error.response.status === 500) {
            setErrorResponse(
              "Something went wrong! Please try again or contact support."
            );
            notifyError(
              "Something went wrong! Please try again or contact support."
            );
          }
          setErrorResponse(
            error.response.message ||
              error.response.data.message ||
              error.response.data.Message
          );
          notifyError(
            error.response.message ||
              error.response.data.message ||
              error.response.data.Message
          );
        } else if (error.request) {
          console.log("Request Error:", error.request);
          setErrorResponse(
            "Something went wrong! Please check your internet connection."
          );
          notifyError(
            "Something went wrong! Please check your internet connection."
          );
        } else {
          console.log("Error:", error.message);
          notifyError(error.message);
        }
      }
    })();
    fetchPaymentLink();
  }, []);

  const fetchPaymentLink = async () => {
    setIsLoading(true);
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/checkout/paymentlink/info?reference=${params?.invoice}`
      );
      const paymentLink = data?.data?.data;
      setSubsidiaryDetails(paymentLink?.subsidiary);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorResponse(error?.response?.data?.message);
      const { message } = error?.response?.data;
      notifyError(message);
    }
  };

  const StyledFormControl = styled(FormControl)(({ theme }) => ({
    marginBottom: "22px"
  }));

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3)
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "white",
      border: "1px solid #ced4da",
      fontSize: 16,
      width: "100%",
      padding: "10px 12px",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow"
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(","),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  }));

  const InvoiceLink = () => {
    const loadCheckout = () => {
      const payInvertPay = new window.PayinvertNS.Payinvert({
        first_name: "",
        last_name: "",
        mobile: "",
        country: "",
        email: "",
        currency: paymentData?.currency,
        option: "",
        amount: paymentData?.amount,
        reference: paymentData?.payment_link,
        description: paymentData?.description,
        apiKey: "",
        encryptionKey: "",
        onCompleted: (data) => {
          console.log(data);
        },
        onError: (error) => {
          console.log(error);
        },
        onClose: () => {}
      });

      payInvertPay.init();
    };

    // const updateValueHandler = (prop, value) => {
    //   setCheckoutPayload((prev) => ({
    //     ...prev,
    //     [prop]: value
    //   }));
    // };

    const printRef = useRef();

    const handleDownloadPdf = async () => {
      const element = printRef.current;
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL("image/png");

      const pdf = new jsPDF();
      const imgProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Invoice-${paymentData?.reference}.pdf`);
    };

    let getInitials = function (string) {
      var names = string?.split(" ");
      var initials = "";

      if (names && names.length > 0) {
        initials += names[0]?.substring(0, 1)?.toUpperCase();

        if (names.length > 1) {
          initials += names[names.length - 1]?.substring(0, 1)?.toUpperCase();
        }
      }

      return initials;
    };

    if (!!errorResponse && !paymentData) {
      return <div>{errorResponse}</div>;
    }

    return (
      <div className={styles.wrapper}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
        <div className={styles.header}>
          <div className={styles.header_left}>
            <button
              className={styles.header_button_left}
              onClick={handleDownloadPdf}
            >
              <span>
                <DownloadIcon />
              </span>
              Download
            </button>
          </div>
          <div className={styles.header_right}>
            <button
              onClick={loadCheckout}
              className={styles.header_button_right}
            >
              Pay Now
            </button>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.body_wrapper} ref={printRef}>
            <div className={styles.body_wrapper_item}>
              <h2>INVOICE</h2>
              <h5>Ref: {paymentData?.reference}</h5>
              <h4>INVOICE FROM</h4>
              <div className={styles.body_desc}>
                <div className={styles.body_desc_img}>
                  {getInitials(subsidiaryDetails?.name)}
                </div>
                <div className={styles.body_desc_content}>
                  <h2>{subsidiaryDetails?.name}</h2>
                  <p>{subsidiaryDetails?.support_email}</p>
                </div>
              </div>

              <div className={styles.list_wrapper}>
                <div className={styles.list}>
                  <div className={styles.listItems}>
                    <h5>Issue Date:&nbsp;</h5>
                    <p>
                      {moment(paymentData?.date_created).format("MMM Do YYYY")}
                    </p>
                  </div>
                  <div className={styles.listItems}>
                    <h5>Bill to: &nbsp;</h5>
                    <p>{paymentData?.customer_name}</p>
                  </div>
                </div>
                <div className={styles.list}>
                  <div className={styles.listItems}>
                    <h5>Due Date:&nbsp;</h5>
                    <p>{moment(paymentData?.due_date).format("MMM Do YYYY")}</p>
                  </div>
                  <div className={styles.listItems}>
                    <h5>Customer's email &nbsp;</h5>
                    <p>{paymentData?.customer_email_address}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.tableNew}>
              {/* Table header */}
              <div className={styles.table_header}>
                <div className={styles.table_header_left}>
                  <h3 className={styles.table_header_h3}>Item Description</h3>
                </div>
                <div className={styles.table_header_right}>
                  <h3 className={styles.table_header_h3}>Quantity</h3>
                  <h3 className={styles.table_header_h3}>Unit Price</h3>
                  <h3 className={styles.table_header_h3}>Amount</h3>
                </div>
              </div>

              {/* Table body */}
              {paymentData?.invoice_items?.map((item) => (
                <>
                  <div className={styles.table_body}>
                    <div className={styles.table_header_left}>
                      <h6 className={styles.table_header_h6}>{item.item}</h6>
                    </div>
                    <div className={styles.table_header_right}>
                      <h6 className={styles.table_header_h6}>
                        {item.quantity}
                      </h6>
                      <h6 className={styles.table_header_h6}>
                        {paymentData?.currency} {item.amount}
                      </h6>
                      <h6 className={styles.table_header_h6}>
                        {paymentData?.currency} {item.quantity * item.amount}
                      </h6>
                    </div>
                  </div>
                  <Divider />
                </>
              ))}

              {/* Table footer */}

              <div className={styles.table_footer}>
                <div className={styles.footer_left}></div>
                <div className={styles.footer_right}>
                  {/*<div className={styles.footer_content}>
                            <h3 className={styles.footer_content_h3}>Subtotal</h3>
                            <h3 className={styles.footer_content_h3}>NGN 45,000</h3>
                      </div>*/}
                  <div className={styles.footer_content}>
                    <h3 className={styles.footer_content_h3}>Tax (0%)</h3>
                    <h3 className={styles.footer_content_h3}>NGN 0.00</h3>
                  </div>
                  <div className={styles.footer_content}>
                    <h3 className={styles.footer_content_h3}>Fees/Discounts</h3>
                    <h3 className={styles.footer_content_h3}>
                      {paymentData?.currency} {paymentData?.discount}
                    </h3>
                  </div>
                </div>
              </div>
              <div className={styles.footer_content_end}>
                <div className={styles.footer_content_dif}>
                  <h3 className={styles.footer_content_h6}>Total</h3>
                  <h3 className={styles.footer_content_h6}>
                    {" "}
                    {paymentData?.currency}{" "}
                    {paymentData?.total_amount - paymentData?.discount}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div id="loader">
        <ClipLoader
          color="green"
          loading={true}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return <>{paymentData && <InvoiceLink />}</>;
}

export default InvoiceRefComponent;
