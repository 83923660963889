import React, { useState, useEffect, CSSProperties } from "react";
import "./Paymentlink.module.css";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import PropagateLoader from "react-spinners/PropagateLoader";

// http://localhost:3001/?first_name=Daniel&last_name=Johnson&mobile=+2348140710794&country=NG&email=arikawedaniel@gmail.com&currency=NGN&option=BT&amount=100&reference=ORD12DC89289033323&description=Food

const PaymentLink = () => {
  const params = useParams();
  const paymentReference = params?.reference;
  const [loading, setLoading] = useState(false);

  const notifySuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const [paymentLinkDetails, setPaymentLinkDetails] = useState();
  const [subsidiaryDetails, setSubsidiaryDetails] = useState();
  const [paymentOptions, setPaymentOptions] = useState();

  const [paymentKeys, setPaymentKeys] = useState();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [currency, setCurrency] = useState(paymentLinkDetails?.currency);
  const [option, setOption] = useState("");
  const [amount, setAmount] = useState(paymentLinkDetails?.amount || undefined);
  const [reference, setReference] = useState();
  const [description, setDescription] = useState("");
  const [apiKey, setApiKey] = useState(
    "PUBKEY-TEST-cc74065c-4ece-40ef-a0bc-106d61de6d7e"
  );

  const fetchPaymentLink = async () => {
    setLoading(true);
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/checkout/paymentlink/info?reference=${paymentReference}`
      );
      const paymentLink = data?.data?.data;
      setPaymentLinkDetails(paymentLink?.payment_link);
      setSubsidiaryDetails(paymentLink?.subsidiary);
      fetchPaymentOptions(paymentLink?.subsidiary?.id);
      setLoading(false);
      const message = data?.data?.message;
    } catch (error) {
      setLoading(false);
      const { message } = error?.response?.data;
      notifyError(message);
    }
  };

  const fetchPaymentKeys = async () => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/checkout/paymentlink/get-keys`,
        {
          reference: paymentReference,
        }
      );
      const message = data?.data?.message;
      setPaymentKeys(data?.data?.data);
    } catch (error) {
      const { message } = error?.response?.data;
    }
  };

  const fetchPaymentOptions = async (subId) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/subsidiary/dashboard/payment/options/view/public?subsidiary_id=${subId}`
      );
      const message = data?.message;
      setPaymentOptions(data?.data);
      // notifySuccess(message);
    } catch (error) {
      const { message } = error?.response?.data;
      notifyError(message);
    }
  };

  useEffect(() => {
    fetchPaymentLink();
    fetchPaymentKeys();
  }, [paymentReference]);

  const inputStyles = {
    background: "rgba(249, 250, 252)",
    fontFamily: "Steradian",
    border: "1px solid #ECF0F4",
    fontSize: "12px",
    borderRadius: "4px",
    borderStyle: "solid",
    color: "#212B4C",
    padding: "8px",
    width: "100%",
  };

  const makePayment = () => {
    if (
      (!!paymentLinkDetails?.amount ? paymentLinkDetails?.amount : amount) ===
        undefined ||
      (!!paymentLinkDetails?.amount ? paymentLinkDetails?.amount : amount) ===
        null ||
      (!!paymentLinkDetails?.amount ? paymentLinkDetails?.amount : amount) ===
        ""
    ) {
      notifyError("Amount is required");
      return;
    }
    const payInvertPay = new window.PayinvertNS.Payinvert({
      first_name: "",
      last_name: "",
      mobile: "",
      country: subsidiaryDetails?.country || country,
      email: "",
      currency: paymentLinkDetails?.currency || currency,
      option: option,
      amount: !!paymentLinkDetails?.amount
        ? paymentLinkDetails?.amount
        : amount,
      reference: paymentLinkDetails?.reference || reference,
      description: "",
      apiKey: "",
      encryptionKey: "",

      onCompleted: (data) => {
        // console.log("complete");
      },
      onClose: () => {
        // console.log("close");
      },
      onError: (error) => {
        // console.log(error, "error wa o");
      },
    });
    payInvertPay.init();
    return;
  };

  const override = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "50px auto",
    borderColor: "red",
  };

  if (loading)
    return (
      <PropagateLoader
        color="#19943c"
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={override}
      />
    );
  return (
    <div className="App">
      <div className="businessName">{subsidiaryDetails?.name}</div>
      {!!paymentLinkDetails?.logo && (
        <img src={paymentLinkDetails?.logo} alt="logo" width={50} height={50} />
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
      <form className="form">
        {/*<label className="label">First Name</label>
        <input
          style={inputStyles}
          name="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <label className="label">Last Name</label>
        <input
          style={inputStyles}
          name="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
        />
        <label className="label">Mobile Number</label>
        <input
          style={inputStyles}
          name="mobile"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          required
        />
        <label className="label">Email Address</label>
        <input
          style={inputStyles}
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          required
      />*/}
        <label className="label">Country:</label>

        <select
          name="country"
          style={inputStyles}
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          <option value={subsidiaryDetails?.country}>
            {subsidiaryDetails?.country}
          </option>
          {/* <option value=""></option>
          <option value="NG">Nigeria</option>
      <option value="GH">Ghana</option>*/}
        </select>

        <label className="label">Currency:</label>

        <select
          name="currency"
          id="currency"
          style={inputStyles}
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
        >
          <option value={paymentLinkDetails?.currency}>
            {paymentLinkDetails?.currency}
          </option>
          {/*<option value=""></option>
    <option value="NGN">Naira</option>*/}
        </select>
        <label className="label">Payment options:</label>

        <select
          name="option"
          id="option"
          style={inputStyles}
          value={option}
          onChange={(e) => setOption(e.target.value)}
        >
          {paymentOptions?.map((option, index) => (
            <option value={option?.code} id={index} key={index}>
              {option?.name}
            </option>
          ))}
        </select>

        <label className="label">Amount</label>
        <input
          style={inputStyles}
          name="amount"
          value={paymentLinkDetails?.amount || amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
        {/*<label className="label">Reference</label>
         <input
           style={inputStyles}
           name="reference"
           value={paymentLinkDetails?.reference}
           onChange={(e) => setReference(e.target.value)}
           required
      />*/}
        {/*<label className="label">Description</label>
        <input
          style={inputStyles}
          name="description"
          value={paymentLinkDetails?.description || description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
         <label className="label">API key</label>
         <input
           style={inputStyles}
           name="apiKey"
           value={paymentKeys?.public_key || apiKey}
           onChange={(e) => setApiKey(e.target.value)}
           required
      />*/}
      </form>

      <div className="gateway-button" onClick={() => makePayment()}>
        Proceed to checkout
      </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${subsidiaryDetails?.name} payment page | powered by Payinvert`}</title>
        <meta
          name="description"
          content={`Pay ${subsidiaryDetails?.name} from any where in the world`}
        />
        <link rel="canonical" href="http://mysite.com/example" />
        <link
          rel="icon"
          type="image/png"
          href={subsidiaryDetails?.logo}
          sizes="16x16"
          data-react-helmet="true"
        />
      </Helmet>
    </div>
  );
};

export default PaymentLink;
